import { Row } from "antd";

const Layout = ({ children }) => {
  return (
    <Row
      type="flex"
      align="middle"
      justify="center"
      className="px-3 mh-page"
      style={{ minHeight: "100vh", backgroundColor: '#ffffff' }}
    > 
      {children}
    </Row>
  );
};

export default Layout;
